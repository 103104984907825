<template>
  <div class="product-wrap programme-integrate">
    <Header is-active="programme" theme="white"></Header>
    <div class="empty-nav"></div>
    <div class="sub-banner banner-integrate"></div>
    <div class="white-wrap">
      <div class="max-center">
        <div class="fz-big align-ct"><h4>云昇自动化系统实施框架</h4></div>
        <div class="auto-img mt-2">
          <img src="../../assets/img/programme/integrate/pic_1.png" alt="云昇自动化系统实施框架">
        </div>
      </div>
    </div>
    <Footer></Footer>
    <BackTop></BackTop>
  </div>
</template>
<script>
import Header from '../../components/Header.vue'
import Footer from '../../components/Footer.vue'
import BackTop from '../../components/BackTop.vue'

export default {
  metaInfo: {
    meta: [
      {
        name: 'keyWords',
        content: '云昇自动化系统-方案中心,云昇新控,智慧水务,智慧工厂,自动化系统集成,智慧照明,重庆云昇新控智能科技有限公司'
      },
      {
        name: 'description',
        content: '云昇新控云昇自动化系统-方案中心,重庆云昇新控智能科技有限公司'
      }
    ]
  },
  components: {
    Header,
    Footer,
    BackTop
  },
  data () {
    return {}
  },
  methods: {}
}
</script>
<style lang="less" scoped>
@import "../../../public/css/product.less";

.programme-integrate {
  .banner-integrate {
    background: url("../../assets/img/programme/integrate/banner.jpg") no-repeat center;
    background-size: cover;
    position: relative;
  }
}

</style>
